import React from "react"
import { colours } from "./helix-tokens"
import {
  radii,
  spacing,
  typeStyles,
  fontSizes,
  fontFamilies,
  fontWeights,
} from "@ucam/helix-tokens"
import { alpha, ThemeOptions } from "@mui/material/styles"
import createTheme from "@mui/material/styles/createTheme"
import ErrorIcon from "@mui/icons-material/ErrorOutline"
import WarningIcon from "@mui/icons-material/WarningAmberOutlined"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import SuccessIcon from "@mui/icons-material/CheckCircleOutline"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { TypographyStyleOptions } from "@mui/material/styles/createTypography"

const stripFontSizeFromStyles = (styles: TypographyStyleOptions) => ({
  ...styles,
  fontSize: "1rem",
})

// Theme colours
interface PaletteColor {
  light?: string
  main: string // Also used for icons
  dark?: string // Used for links
  contrastText?: string
  border?: string
}

const brand: PaletteColor = {
  contrastText: colours.cambridgeBlue2,
  light: colours.cambridgeBlue3,
  main: colours.cambridgeBlue7,
  dark: colours.cambridgeBlue9,
}

const errorPalette: PaletteColor = {
  light: colours.cherry5,
  main: colours.cherry8,
  dark: colours.cherry9,
  contrastText: colours.cherry1,
  border: colours.cherry7,
}

const successPalette: PaletteColor = {
  light: colours.green5,
  main: colours.green9,
  dark: colours.cambridgeBlue8,
  contrastText: colours.green1,
  border: colours.green7,
}

const warningPalette: PaletteColor = {
  light: colours.crest4,
  main: colours.crest8,
  dark: colours.crest9,
  contrastText: colours.crest1,
  border: colours.crest7,
}

const infoPalette: PaletteColor = {
  light: colours.indigo4,
  main: colours.indigo8,
  dark: colours.indigo9,
  contrastText: colours.white,
  border: colours.indigo6,
}

// Create a base version of the theme
// So we can refer to colours and breakpoints in our other styles

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 992,
      lg: 992,
      xl: 992,
    },
  },
  palette: {
    primary: brand,
    secondary: {
      light: colours.grey1,
      main: colours.grey2,
      dark: colours.grey3,
    },
    success: successPalette,
    error: errorPalette,
    info: infoPalette,
    warning: warningPalette,
    grey: {
      100: colours.grey1,
      200: colours.grey2,
      300: colours.grey3,
      400: colours.grey4,
      500: colours.grey6,
      600: colours.grey8,
      700: colours.grey9,
      800: colours.grey10,
      900: colours.grey11,
    },
    background: {
      default: colours.white,
    },
  },
  typography: {
    fontFamily: fontFamilies.sans,
    fontWeightLight: fontWeights.light,
    fontWeightRegular: fontWeights.regular,
    fontWeightMedium: fontWeights.semibold,
    fontWeightBold: fontWeights.bold,
  },
})

// Typography

const body: { [level: number]: TypographyStyleOptions } = {
  1: {
    ...typeStyles.paragraph.paragraph2,
    color: colours.black,
    marginBottom: spacing["16px"],
  },
  2: {
    ...typeStyles.paragraph.paragraph3,
    color: colours.grey11,
    marginBottom: spacing["12px"],
  },
}

const heading: { [level: number]: TypographyStyleOptions } = {
  1: {
    ...typeStyles.headings.heading1,
    marginBottom: spacing["40px"],
    "& .MuiSvgIcon-root": {
      verticalAlign: "text-top",
      marginRight: spacing["8px"],
    },
  },
  2: {
    ...typeStyles.headings.heading2,
    marginBottom: spacing["20px"],
  },
  3: {
    ...typeStyles.headings.heading3,
    paddingBottom: spacing["4px"],
    marginBottom: spacing["64px"],
    borderBottom: `1px solid ${colours.grey3}`,
  },
  4: {
    ...typeStyles.headings.heading4,
    // Override Helix theme
    // Reason: https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/admissions-portal/ap-frontend/-/issues/697#note_430430
    fontWeight: fontWeights.medium,
    marginBottom: spacing["16px"],
    marginTop: spacing["40px"],
    // Stop two `h4`s following each other having a gap between
    "& + .MuiTypography-h4": {
      marginTop: 0,
    },
  },
  5: typeStyles.headings.heading5,
  6: typeStyles.headings.heading6,
}

const label: TypographyStyleOptions = {
  ...heading[4],
  transform: "none",
  marginBottom: spacing["12px"],
  position: "inherit",
  whiteSpace: "normal",
  maxWidth: "100%",
  width: "100%",
}

const list: TypographyStyleOptions = {
  marginTop: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: spacing["24px"],
  marginBottom: spacing["32px"],
}

const listItem: TypographyStyleOptions = {
  marginBottom: spacing["4px"],
  color: "inherit",
  display: "list-item",
  padding: `0 0 0 ${spacing["4px"]}`,
}

const link: TypographyStyleOptions = {
  color: colours.cambridgeBlue8,
  fontFamily: fontFamilies.sans,
  textDecoration: "underline",
  overflowWrap: "break-word",
  "&:hover": {
    color: colours.cambridgeBlue9,
  },
  "&:active": {
    color: colours.cambridgeBlue10,
  },
  "& .cam-logo": {
    color: colours.black,
  },
}

// Theme

const CamMuiThemeOptions: ThemeOptions = {
  typography: {
    // Including font sizes here has impacts on wider app since it uses `em`, which stack as they're nested
    body1: {
      ...stripFontSizeFromStyles(body[1]),
      // This gets applied to the `body`
      // So reset the font size and remove the margin
      fontSize: "1rem",
      marginBottom: 0,
    },
    // We set these here so we can refer to them throughout the code in our theme
    body2: body[2],
    subtitle1: {
      ...body[2],
      fontSize: fontSizes["14px"],
    },
  },
  components: {
    // Links
    MuiLink: {
      defaultProps: {
        underline: "always",
        color: link.color,
      },
      styleOverrides: {
        root: {
          ...link,
          color: undefined,
          // Correctly handle Link[component="button"]
          "&:is(button)": {
            display: "inline-block",
            verticalAlign: "text-top",
            fontSize: "inherit",
          },
          "&.Mui-disabled": {
            color: colours.grey10,
            textDecoration: "none",
            cursor: "not-allowed",
          },
          "&.Mui-focusVisible": {
            boxShadow: `0 0 0 5px ${colours.indigo6}`,
            outline: "none",
            borderRadius: "4px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: body[1],
        body2: body[2],
        h1: heading[1],
        h2: heading[2],
        h3: heading[3],
        h4: heading[4],
        h5: heading[5],
        h6: heading[6],
      },
    },
    // Buttons
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        color: "primary",
      },
      styleOverrides: {
        root: {
          minWidth: spacing["40px"],
          "&.Mui-disabled": {
            borderColor: colours.grey4,
          },
          "&:hover": {
            boxShadow: "none",
          },
          "&.Mui-focusVisible": {
            boxShadow: `0 0 0 2px ${colours.white}, 0 0 0 7px ${colours.indigo6}`,
          },
        },
        contained: {
          textTransform: "none",
          fontWeight: fontWeights.medium,
          boxShadow: "none",
          padding: `${spacing["8px"]} ${spacing["16px"]}`,
          fontSize: fontSizes["18px"],
        },
        sizeSmall: {
          padding: `${spacing["4px"]} ${spacing["12px"]}`,
          fontSize: fontSizes["16px"],
        },
        outlined: {
          borderWidth: "2px",
          "&:hover": {
            borderWidth: "2px",
          },
        },
        containedPrimary: {
          color: colours.white,
          fontWeight: fontWeights.semibold,
        },
        outlinedPrimary: {
          borderColor: brand.main,
        },
        outlinedSecondary: {
          borderColor: colours.grey3,
        },
        text: {
          fontFamily: fontFamilies.sans,
          fontWeight: fontWeights.semibold,
          textTransform: "none",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingTop: spacing["8px"],
          paddingBottom: spacing["8px"],
          paddingLeft: spacing["12px"],
          paddingRight: spacing["12px"],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: body[2].fontSize,
          paddingTop: spacing["4px"],
          paddingBottom: spacing["4px"],
          paddingLeft: spacing["8px"],
          paddingRight: spacing["8px"],
          marginBottom: 0,
          "&.Mui-focusVisible": {
            boxShadow: `0 0 0 2px ${colours.white}, 0 0 0 7px ${colours.indigo6}`,
            borderRadius: "4px",
            background: colours.white,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "&.MuiList-unordered": {
            ...list,
            listStyle: "disc",
            "& > li": listItem,
          },
          "&.MuiList-ordered": {
            ...list,
            listStyle: "decimal",
            "& > li": listItem,
          },
          "&.verticalNavMenu": {
            paddingRight: spacing["40px"],
            "& > li": {
              padding: 0,
              marginBottom: spacing["4px"],
              "& > .MuiListItemButton-root": {
                paddingRight: spacing["40px"],
                "&.Mui-selected": {
                  borderLeft: `4px solid ${brand.main}`,
                  backgroundColor: colours.grey1,
                },
                "&:hover": {
                  backgroundColor: colours.grey2,
                },
                "&.Mui-focusVisible": {
                  boxShadow: `0 0 0 2px ${colours.white}, 0 0 0 7px ${colours.indigo6}`,
                  borderRadius: "4px",
                  background: colours.white,
                  "&.Mui-selected": {
                    backgroundColor: colours.grey1,
                  },
                  zIndex: 1,
                },
              },
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: spacing["4px"],
          paddingBottom: spacing["4px"],
          "&.MuiListItem-link": {
            ...link,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          margin: 0,
          "&.MuiListItemButton-static": {
            color: colours.grey9,
            opacity: 1,
            paddingTop: 0,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colours.grey2}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: body[2].fontSize,
          borderBottom: "none",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: colours.grey1,
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root": {
            borderBottom: "none",
          },
        },
      },
    },
    // Application bar  / header
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: spacing["80px"],
          "@media print": {
            boxShadow: "none",
            border: "none",
            "& > nav": {
              paddingLeft: spacing["16px"],
            },
          },
        },
        colorPrimary: {
          background: colours.white,
          borderBottom: `1px solid ${colours.grey2}`,
          boxShadow: `0 0 8px 0 ${colours.grey2}`,
        },
        colorSecondary: {
          border: "none",
          backgroundColor: colours.indigo8,
          "& .MuiLink-root": {
            color: colours.indigo2,
            "&:hover": {
              color: colours.white,
            },
          },
        },
      },
    },
    // Avatar
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: fontFamilies.serif,
          position: "relative",
          color: colours.white,
          backgroundColor: brand.main,
          // Background effect
          "&::before": {
            content: '""',
            position: "absolute",
            width: "280%",
            height: "280%",
            top: "-180%",
            right: "-200%",
            borderRadius: "100%",
            background: colours.black,
            opacity: 0.06,
          },
        },
      },
    },
    // Chip
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: body[2].fontSize,
          borderRadius: radii["4px"],
          border: `1px solid ${colours.grey3}`,
          background: colours.grey1,
        },
        colorSecondary: {
          borderColor: colours.cambridgeBlue4,
          color: colours.cambridgeBlue9,
          background: colours.cambridgeBlue1,
        },
        colorPrimary: {
          border: "none",
          color: colours.white,
          fontWeight: fontWeights.semibold,
          background: brand.main,
        },
      },
    },
    // Alerts
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          ...body[1],
          fontWeight: fontWeights.bold,
          marginBottom: 0,
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <InfoIcon fontSize="inherit" htmlColor={infoPalette.dark} />,
          warning: (
            <WarningIcon fontSize="inherit" htmlColor={warningPalette.dark} />
          ),
          error: <ErrorIcon fontSize="inherit" htmlColor={errorPalette.dark} />,
          success: (
            <SuccessIcon fontSize="inherit" htmlColor={successPalette.dark} />
          ),
        },
      },
      styleOverrides: {
        root: {
          fontSize: "1rem",
          // Have to do this here rather than in the `icon` ones below
          // As default is higher specificity than there
          "& .MuiAlert-icon": {
            color: body[2].color,
          },
        },
        icon: {
          fontSize: fontSizes["28px"],
          paddingTop: spacing["12px"],
        },
        message: {
          "& > *:first-of-type": {
            marginTop: 0,
          },
          "& > *:last-child": {
            marginBottom: 0,
          },
        },
        standardInfo: {
          borderColor: infoPalette.border,
          color: infoPalette.dark,
          background: infoPalette.contrastText,
          "& .MuiLink-root": {
            color: infoPalette.dark,
          },
        },
        standardError: {
          borderColor: errorPalette.border,
          color: errorPalette.dark,
          background: errorPalette.contrastText,
          "& .MuiLink-root": {
            color: errorPalette.dark,
          },
        },
        standardWarning: {
          borderColor: warningPalette.border,
          color: warningPalette.dark,
          background: warningPalette.contrastText,
          "& .MuiLink-root": {
            color: warningPalette.dark,
          },
        },
        standardSuccess: {
          borderColor: successPalette.border,
          color: successPalette.dark,
          background: successPalette.contrastText,
          "& .MuiLink-root": {
            color: successPalette.dark,
          },
        },
      },
    },
    // Paper
    MuiPaper: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          border: `1px solid ${colours.grey2}`,
        },
        elevation0: {
          boxShadow: "none",
        },
        elevation1: {
          boxShadow: `0 4px 8px -2px ${alpha(colours.grey11, 0.1)}`,
        },
      },
    },
    // Accordions
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: colours.grey1,
          boxShadow: "none",
          border: `1px solid ${colours.grey2}`,
          borderRadius: radii["4px"],
          marginTop: spacing["20px"],
          marginBottom: spacing["20px"],
          width: "100%",
          // Hide the shadow at the top
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            marginBottom: spacing["20px"],
            "&:last-of-type": {
              marginBottom: spacing["20px"],
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ExpandMoreIcon />,
      },
      styleOverrides: {
        root: {
          ...link,
          textDecoration: "none",
          fontSize: body[1].fontSize,
          "& p": {
            margin: 0,
            fontWeight: fontWeights.regular,
          },
          "&.Mui-focusVisible": {
            boxShadow: `0 0 0 2px ${colours.white}, 0 0 0 7px ${colours.indigo6}`,
            borderRadius: "4px",
            background: colours.grey1,
          },
        },
      },
    },
    // Form controls and labels
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          display: "block",
          ...body[2],
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          // Radio and checkbox item wrappers
          color: body[2].color,
          "&.Mui-error": {
            color: errorPalette.dark,
          },
        },
        label: {
          // Radio and checkbutton label text
          ...body[2],
          color: "inherit",
          marginBottom: 0,
          "& label": {
            marginTop: 0,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        // Input labels and fieldset legends
        root: label,
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        focused: false,
        shrink: true,
      },
      styleOverrides: {
        root: {
          // Just input labels
          // Reapply label styles to override MUI specificity
          ...label,
        },
      },
    },
    // Inputs
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          padding: `${spacing["12px"]} ${spacing["8px"]}`,
        },
        root: {
          marginBottom: 0,
          border: `1px solid ${colours.grey10}`,
          transition:
            "border-color 0.1s linear, border-width 0.1s linear, margin-top 0.1s linear, margin-bottom 0.1s linear",
          width: "100%",
          borderRadius: radii["4px"],
          "& input": {
            lineHeight: 1.5,
            height: "1.5em",
            padding: 0,
          },
          "&.Mui-disabled": {
            borderColor: colours.grey3,
          },
          "&.Mui-focused": {
            boxShadow: `0 0 0 3px ${colours.white}, 0 0 0 7px ${colours.indigo6}`,
            borderRadius: "4px",
          },
          "&.Mui-error": {
            borderColor: errorPalette.main,
            borderWidth: radii["4px"],
            marginTop: "-1px",
            marginBottom: 0,
            "&.Mui-focused": {
              boxShadow: `0 0 0 2px ${colours.white}, 0 0 0 7px ${colours.indigo6}`,
              borderRadius: "4px",
            },
          },
          // Handle the pushing down by the border width increasing.
          "&:not(.Mui-error)": {
            marginTop: "2px",
            marginBottom: "3px",
          },
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colours.grey9,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: `${spacing["12px"]} ${spacing["8px"]}`,
          "& .MuiAutocomplete-input": {
            padding: 0,
            "&.Mui-focusVisible": {
              boxShadow: `0 0 0 3px ${colours.white}, 0 0 0 7px ${colours.indigo6}`,
              borderRadius: "4px",
            },
          },
        },
        listbox: {
          paddingRight: "2rem",
          "& li": {
            paddingLeft: 8,
            marginTop: spacing["4px"],
            marginLeft: "1rem",
            cursor: "pointer",
            ":hover": {
              backgroundColor: colours.grey2,
            },
            "&.Mui-focusVisible": {
              boxShadow: `0 0 0 3px ${colours.white}, 0 0 0 7px ${colours.indigo6}`,
              borderRadius: "4px",
              background: colours.white,
            },
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          "&:is(select)": {
            padding: 0,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
    // Checkboxes
    MuiCheckbox: {
      defaultProps: {
        disableTouchRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          color: colours.grey10,
          padding: "4px",
          margin: "5px",
          "& svg": {
            zIndex: 1,
          },
          "&.MuiCheckbox-dark": {
            "&:not(.Mui-checked) svg": {
              fill: colours.white,
            },
            // Add a background behind the icon
            "&::before": {
              content: '""',
              position: "absolute",
              left: "9px",
              right: "9px",
              top: "9px",
              bottom: "9px",
              margin: "-1px",
              background: colours.white,
            },
          },
          "&.Mui-checked svg": {
            fill: brand.main,
          },
          "&.Mui-focusVisible": {
            boxShadow: `inset 0 0 0 4px ${colours.indigo6}`,
            borderRadius: "8px",
          },
          "&.Mui-error": {
            "& svg": {
              color: errorPalette.main,
            },
          },
        },
      },
    },
    // Radios
    MuiRadio: {
      defaultProps: {
        disableTouchRipple: true,
        disableFocusRipple: true,
        icon: (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="11.5" stroke={colours.grey10} />
          </svg>
        ),
        checkedIcon: (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="11.5" stroke={colours.grey10} />
            <circle cx="12" cy="12" r="8" fill={brand.main} />
          </svg>
        ),
      },
      styleOverrides: {
        root: {
          color: colours.grey10,
          padding: "6px",
          marginLeft: "3px",
          marginRight: "2px",
          "&.Mui-focusVisible": {
            boxShadow: `inset 0 0 0 4px ${colours.indigo6}`,
          },
          "&.Mui-error": {
            "& svg > circle": {
              stroke: errorPalette.dark,
              r: 10.5,
              strokeWidth: 2.5,
            },
          },
        },
      },
    },
  },
}

const theme = createTheme(baseTheme, CamMuiThemeOptions)

export default theme
